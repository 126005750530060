





















































import {Prop, Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import DeviceList from './DeviceList.vue';
// import FmondList from './FmondList.vue';
// import ForecourtView from './ForecourtView.vue';
import AlarmListCard from './AlarmListCard.vue';
import EventListCard from './EventListCard.vue';
import { EventBus } from '@/main';
import {OutputFmonDaemonDTO, OutputLocationDTO} from "@/api";
import LocationStatusIcon from "@/components/LocationStatusIcon.vue";
import FmonDaemonStatusIcon from "@/components/FmonDaemonStatusIcon.vue";
import Loading from "@/components/Loading.vue";
import {formatApiTimeString} from "@/utils";

@Component({
  components: {
    Loading,
    FmonDaemonStatusIcon,
    LocationStatusIcon,
    AlarmListCard,
    DeviceList,
    EventListCard,
    // FmondList,
    // ForecourtView,
  },
})
export default class LocationView extends Vue {
  @Prop(Number) readonly organizationId;
  @Prop(Number) readonly locationId;

  public location: OutputLocationDTO|null = null;
  public fmonDaemon: OutputFmonDaemonDTO|null = null;
  public loading: boolean = true;

  public async created() {
    EventBus.$on('fmond', () => {
      this.fetchLocation();
    });
    EventBus.$on('device', () => {
      this.fetchLocation();
    });
  }

  public async mounted() {
    await this.fetchLocation();
  }

  public async fetchLocation() {
    [this.location, this.fmonDaemon] = await Promise.all([
      await this.$store.dispatch('locations/get', { locationId: this.locationId }),
      await this.$store.dispatch('locations/getFmonDaemon', { locationId: this.locationId })
    ])
    this.loading = false;
  }

  public cancel() {
    this.$router.back();
  }

  get lastFmonDaemonCommunication() {
    if (this.loading) {
      return "";
    }

    if (this.fmonDaemon === null || this.fmonDaemon.last_communication === undefined) {
      return 'Unavailable'
    } else {
      return formatApiTimeString(this.fmonDaemon.last_communication)
    }
  }
}
