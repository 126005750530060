<template>
  <v-icon :color="fmondStatusColor">developer_board</v-icon>
</template>

<script>
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class FmonDaemonStatusIcon extends Vue {
  @Prop() fmonDaemonData;

  get fmondStatusColor() {
    if (!this.fmonDaemonData) {
      return 'grey'
    }

    if (this.fmonDaemonData.status === 'UNKNOWN') {
      return 'error'
    }

    return 'success';
  }
}
</script>

<style scoped>

</style>
